<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-11-21 14:31:11
 * @LastEditTime: 2022-12-02 18:53:33
 * @LastEditors: cyy
 * @Description: 秒杀首页
 * @FilePath: \teacherdingding.com\src\views\MarketingCenter\seckill\main.vue
-->
<template>
  <div class="seckill">
    <div class="header">
      <el-button
        type="primary"
        @click="$router.push({ path: '/seckill/newSeckill' })"
      >
        新建秒杀
      </el-button>
      <div class="right">
        <span class="tips mr10">状态</span>
        <el-select
          v-model="post.status"
          size="medium"
          style="width:150px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          clearable
          v-model="post.search"
          size="medium"
          style="width:188px"
          @keyup.enter.native="toSearch"
          class="ml20 mr20"
          placeholder="输入名称搜索"
        ></el-input>
        <el-button size="medium" type="primary" @click="toSearch">
          搜索
        </el-button>
      </div>
    </div>
    <pagination2
      :option="post2"
      url="/Seckill/seckillList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="活动名称" min-width="90px">
            <template slot-scope="{ row }">
              <div class="hidetext" :title="row.name">{{ row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="状态" min-width="70px">
            <template slot-scope="{ row }">
              <span v-if="row.status == 1" style="color:#FF3535">未开始</span>
              <span v-else-if="row.status == 2" style="color:#3D80EF">
                进行中
              </span>
              <span v-else style="color:#B6B6B6">已结束</span>
            </template>
          </el-table-column>
          <el-table-column label="有效期" min-width="180">
            <template slot-scope="{ row }">
              <p>
                起：{{ row.start_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </p>
              <p>
                止：{{ row.end_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="活动商品信息" min-width="240px">
            <template slot-scope="{ row }">
              <div class="contain">
                <div class="tu">
                  <img :src="row.goods_photo" alt="" />
                  <div class="tips2 ">
                    {{ row.goods_type | getGoodtype }}
                  </div>
                </div>
                <div class="right">
                  <div class="title hidetext" :title="row.goods_name">
                    {{ row.goods_name }}
                  </div>
                  <div class="price">￥{{ row.goods_price }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="秒杀价(元)" min-width="90px">
            <template slot-scope="{ row }">{{ row.seckill_price }}</template>
          </el-table-column>
          <el-table-column label="剩余数量" prop="stock_num"></el-table-column>
          <el-table-column
            label="已购数量"
            prop="purchased_num"
          ></el-table-column>
          <el-table-column
            min-width="120px"
            label="成交金额(元)"
            prop="purchased_price"
          ></el-table-column>
          <el-table-column label="操作" min-width="160">
            <template slot-scope="{ row }">
              <!-- 分享  |  编辑  |  结束 -->
              <el-button
                type="text"
                @click="tuiguang(row)"
                v-if="row.status == 1 || row.status == 2"
              >
                分享
              </el-button>
              <el-divider
                direction="vertical"
                v-if="row.status == 1 || row.status == 2"
              ></el-divider>
              <el-button
                type="text"
                v-if="row.status == 1"
                @click="
                  $router.push({
                    path: '/seckill/newSeckill',
                    query: { id: row.seckill_id, able: 1 },
                  })
                "
              >
                编辑
              </el-button>
              <el-button
                type="text"
                v-if="row.status == 2"
                @click="
                  $router.push({
                    path: '/seckill/newSeckill',
                    query: { id: row.seckill_id, able: 2 },
                  })
                "
              >
                查看
              </el-button>
              <el-divider
                direction="vertical"
                v-if="row.status == 2"
              ></el-divider>
              <el-button
                type="text"
                v-if="row.status == 2"
                @click="stopAcitvity(row)"
              >
                结束
              </el-button>
              <el-button type="text" v-if="row.status == 3" @click="del(row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
    <!-- 推广 -->
    <extension
      v-if="status"
      :selectrow="selectrow"
      :dialogstatus.sync="status"
    ></extension>
  </div>
</template>

<script>
import extension from './components/extension'
import bg from '@/mixin/background'
export default {
  mixins: [bg],

  components: {
    extension,
  },

  data() {
    return {
      selectrow: {},
      status: false,
      options: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 2,
          label: '进行中',
        },
        {
          value: 1,
          label: '未开始',
        },
        {
          value: 3,
          label: '已结束',
        },
      ],
      post2: {},
      post: {
        status: 0,
        search: '',
      },
    }
  },

  filters: {
    getGoodtype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
        case 4:
          text = '系列课'
          break
        case 6:
          text = '图文'
          break
        case 8:
          text = '知识商品'
          break
      }
      return text
    },
  },

  methods: {
    // 删除
    del(row) {
      this.$confirm('是否确定删除该活动呢?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Seckill/delSeckill',
            data: { seckill_id: row.seckill_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },
    // 结束
    async stopAcitvity(row) {
      this.$confirm('是否确定结束该活动呢?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Seckill/end',
            data: { seckill_id: row.seckill_id },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 推广
    tuiguang(row) {
      this.selectrow = row

      this.$nextTick(() => {
        this.status = true
      })
    },
    toSearch() {
      this.post2 = _.assign({}, this.post)
    },
  },
}
</script>

<style lang="scss" scoped>
.seckill {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  // ::v-deep .el-table .cell {
  //   padding: 0 !important;
  // }
  ::v-deep .el-divider {
    background-color: #1b9d97 !important;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .tips {
      font-size: 14px;
      color: #333333;
    }
  }
  .contain {
    display: flex;
    align-items: center;
    .tu {
      flex-shrink: 0;
      width: 68px;
      height: 41px;
      position: relative;
      background: #d0d0d0;
      img {
        width: 100%;
        height: 100%;
      }
      .tips2 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 16px;
        line-height: 16px;
        background: rgba(0, 0, 0, 087);
        text-align: center;
        font-size: 12px;
        color: #ffffff;
      }
    }
    .right {
      overflow: hidden;
      flex: 1;
      height: 41px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 13px;
        font-weight: bold;
        color: #333333;
        line-height: 13px;
      }
      .price {
        font-size: 12px;
        color: #ff3535;
        line-height: 12px;
      }
    }
  }
}
</style>
